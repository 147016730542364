import React, { useEffect, useState } from "react";
import "../../styles/booking-form.css";
import ReactPlaceholder from "react-placeholder";
import { format } from "date-fns";
import "react-placeholder/lib/reactPlaceholder.css";
import { AiFillCheckCircle } from "react-icons/ai";
import { BiSolidNotepad, BiSolidTime, BiSolidUser } from "react-icons/bi";
import { BsFillCalendarEventFill, BsFillTelephoneFill } from "react-icons/bs";
import { FaLocationDot, FaUsers } from "react-icons/fa6";
import { GrMail } from "react-icons/gr";
import { IoIosArrowDropdown } from "react-icons/io";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Spinner,
} from "reactstrap";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  bookingCarFetched,
  bookingCarStart,
  bookingCarSuccess,
  insuranceSelect,
} from "../features/carBookingSlice";
import axiosInstance from "../../utils/axiosUtil";
import { MdCancel, MdLuggage } from "react-icons/md";
import { ToastContainer, toast } from "react-toastify";
import {
  getLocationFailure,
  getLocationStart,
  getLocationSuccess,
} from "../features/getLocationSlice";
import TermsConditions from "./TermsConditions";
import BookingAgeCheck from "./BookingAgeCheck";

const BookingForm = () => {
  const location = useLocation();
  const params = useParams();
  const { findCar } = useSelector((state) => state.findCar);
  const { carDetails } = useSelector((state) => state.carDetails);
  const { user, token } = useSelector((state) => state.auth);
  const {
    // carDetails: bookingCarDetails,
    acceptedTermsCondi,
    // userDetails,
    // insuranceSelected,
    fetching,
  } = useSelector((state) => state.bookingCar);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    // firstname: "",
    // lastname: "",
    // email: "",
    // mobile_no: "",
    fromAddress: "",
    toAddress: "",
    person: "1 person",
    luggage: "1 luggage",
    journeyDate: "",
    journeyEndDate: "",
    journeyTime: "",
    journeyEndTime: "",
  });

  const { pickupLocations, dropOffLocations, loadingLocation } = useSelector(
    (state) => state.getLocation
  );
  const [insurance, setInsurance] = useState();
  const [insuranceType, setInsurancetype] = useState("");

  const [userAge, setUserAge] = useState(null);
  const [ageErr, setAgeErr] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showTermsCondi, setShowTermsCondi] = useState(true);
  const [days, setDays] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const toastOptions = {
    position: "bottom-center",
    autoClose: 3000,
    pauseOnHover: true,
    draggable: true,
    theme: "dark",
  };

  useEffect(() => {
    if (location.state?.fromHome) {
      setValues({
        fromAddress: findCar?.fromAddress,
        toAddress: findCar?.toAddress,
        person: findCar?.person || "1 person",
        luggage: findCar?.luggage || "1 luggage",
        journeyDate: findCar?.startDate,
        journeyEndDate: findCar?.endDate,
        journeyTime: findCar?.startTime,
        journeyEndTime: findCar?.endTime,
      });
    }
  }, []);

  // useEffect(() => {
  //   if (bookingCarDetails?._id !== params?.id) {
  //     dispatch(setBookingCar());
  //   }
  // }, []);
  useEffect(() => {
    if (
      values.journeyEndDate &&
      values.journeyDate &&
      values.journeyEndTime &&
      values.journeyTime
    ) {
      const journeyStartDateTime = new Date(
        `${format(new Date(values.journeyDate), "MMMM dd, yyyy")}, ${
          values.journeyTime
        }`
      );
      const journeyEndDateTime = new Date(
        `${format(new Date(values.journeyEndDate), "MMMM dd, yyyy")}, ${
          values.journeyEndTime
        }`
      );
      if (journeyStartDateTime.getDate() === journeyEndDateTime.getDate()) {
        localStorage.setItem("journeyDays", "1");
        setDays(1);
      } else {
        const days = Math.ceil(
          (journeyEndDateTime - journeyStartDateTime) / (1000 * 60 * 60 * 24)
        );
        setDays(days);
        localStorage.setItem("journeyDays", JSON.stringify(days));
      }
    }
  }, [values]);
  useEffect(() => {
    const fetchLocation = async () => {
      dispatch(getLocationStart());
      try {
        const { data } = await axiosInstance.get("/api/location/get-locations");

        dispatch(getLocationSuccess(data));
      } catch (error) {
        // console.log(error);
        dispatch(getLocationFailure(error));
      }
    };

    fetchLocation();
  }, []);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const calculateTotal = async () => {
    dispatch(bookingCarStart());
    try {
      const { data } = await axiosInstance.post(
        "/api/booking/calculate-total",
        {
          startDate: values?.journeyDate,
          endDate: values?.journeyEndDate,
          startTime: values?.journeyTime,
          endTime: values?.journeyEndTime,
          carId: params.id,
          insurance: insurance,
          insuranceType: insuranceType,
        }
      );

      dispatch(bookingCarFetched(data.total));
    } catch (error) {
      dispatch(bookingCarFetched(error?.response?.data?.message));
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();
  };

  const onReserve = async () => {
    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/api/booking/check-availability`,
        {
          startDate: values.journeyDate,
          endDate: values.journeyEndDate,
          endTime: values.journeyEndTime,
          startTime: values.journeyTime,
          carId: params?.id,
        }
        // { headers: { Authorization: token } }
      );

      if (data.success) {
        dispatch(
          bookingCarSuccess({
            carId: carDetails?._id,
            carName: carDetails?.name,
            brand: carDetails?.brand,
            details: carDetails?.details,
            price: carDetails?.price,
            discount: carDetails?.discount,
            image: carDetails?.images,
            model: carDetails?.model,
            noOfSeat: carDetails?.noOfSeat,
            rating: carDetails?.rating,
            speed: carDetails?.speed,
            gps: carDetails?.gps,
            seatType: carDetails?.seatType,
            automatic: carDetails?.automatic,
            insurance,
            insuranceType,
            // firstname: values.firstname,
            // lastname: values.lastname,
            // email: values.email,
            // mobile_no: values.mobile_no,
            userAge: userAge,
            fromAddress: values.fromAddress,
            toAddress: values.toAddress,
            person: values.person,
            luggage: values.luggage,
            journeyDate: values.journeyDate,
            journeyEndDate: values.journeyEndDate,
            journeyTime: values.journeyTime,
            journeyEndTime: values.journeyEndTime,
          })
        );

        // dispatch(insuranceSelect(insurance));
        if (!localStorage.getItem("user")) {
          localStorage.setItem("name",name);
          localStorage.setItem("email",email);
          localStorage.setItem("phone",phone)
        }
        await calculateTotal();

        navigate("/order", { replace: true });
        setLoading(false);
      } else {
        toast.error(data.message, toastOptions);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong! Please try again.", toastOptions);
      setLoading(false);
    }
  };

  return (
    <>
      {/* <TermsConditions
        showTermsCondi={showTermsCondi}
        setShowTermsCondi={setShowTermsCondi}
        setShowAge={setShowAge}
        showAge={showAge}
        setShowForm={setShowForm}
      /> */}

      <BookingAgeCheck
        setShowTermsCondi={setShowTermsCondi}
        setShowAge={setShowAge}
        showAge={showAge}
        setShowForm={setShowForm}
        setUserAge={setUserAge}
        setAgeErr={setAgeErr}
        ageErr={ageErr}
        userAge={userAge}
      />

      <div className="terms-condi-booking-form">
        <div
          onClick={() => {
            // if (!ageErr && userAge >= 21) {
            setShowTermsCondi(false);
            setShowAge(false);
            setShowForm(!showForm);
            // }
          }}
          className="terms-condi-booking-upper"
        >
          <p>
            {values.fromAddress?.length <= 0 ||
            values.toAddress?.length <= 0 ||
            values.person?.length <= 0 ||
            values.luggage?.length <= 0 ||
            values.journeyDate?.length <= 0 ||
            values.journeyTime?.length <= 0 ||
            insuranceType === "" ? (
              <MdCancel color="red" style={{ marginRight: "0.5rem" }} />
            ) : (
              <AiFillCheckCircle
                color="green"
                style={{ marginRight: "0.5rem" }}
              />
            )}
            Step 2
          </p>

          <p>
            <IoIosArrowDropdown />
          </p>
        </div>
        {showForm && (
          <ReactPlaceholder
            type="text"
            color="#F0F0F0"
            showLoadingAnimation
            rows={10}
            delay={200}
            ready={!loadingLocation}
          >
            <Form onSubmit={submitHandler}>
              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <BiSolidUser color="#7a7a87" />{" "}
                <Label style={{ color: "#7a7a87" }}>Name</Label>
                <Input
                  name="name"
                  value={user?.name || name}
                  // onChange={handleChange}
                  onChange={(e) => setName(e.target.value)}
                  readOnly={user?.name? true:false}
                  type="text"
                  placeholder="Name"
                />
              </FormGroup>

              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <GrMail color="#7a7a87" />{" "}
                <Label style={{ color: "#7a7a87" }}>Email</Label>
                <Input
                  type="email"
                  readOnly={user?.name? true:false}
                  placeholder="Email"
                  name="email"
                  value={user?.email || email}
                  onChange={(e) => setEmail(e.target.value)}
                  // onChange={handleChange}
                />
              </FormGroup>
              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <BsFillTelephoneFill style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Phone number</Label>
                <Input
                  type="number"
                  readOnly={user?.name? true:false}
                  placeholder="Phone Number"
                  name="mobile_no"
                  value={user?.phone || phone}
                  onChange={(e) => setPhone(e.target.value)}
                  // onChange={handleChange}
                />
              </FormGroup>

              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <FaLocationDot color="#7a7a87" />{" "}
                <Label style={{ color: "#7a7a87" }}>Pick-up</Label>
                <Input
                  readOnly={location.state?.fromHome}
                  type="select"
                  placeholder="From Address"
                  name="fromAddress"
                  value={values.fromAddress}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {pickupLocations?.map((pickUp) => (
                    <option key={pickUp} value={pickUp}>
                      {pickUp}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <FaLocationDot style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Drop-of</Label>
                <Input
                  readOnly={location.state?.fromHome}
                  type="select"
                  placeholder="To Address"
                  name="toAddress"
                  value={values.toAddress}
                  onChange={handleChange}
                >
                  <option value="">Select</option>
                  {dropOffLocations?.map((dropOf) => (
                    <option key={dropOf} value={dropOf}>
                      {dropOf}
                    </option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <FaUsers style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>No. of persons</Label>
                <Input
                  type="select"
                  name="person"
                  value={values.person}
                  onChange={handleChange}
                >
                  <option value="1 person">1 Person</option>
                  <option value="2 person">2 Person</option>
                  <option value="3 person">3 Person</option>
                  <option value="4 person">4 Person</option>
                  <option value="5+ person">5+ Person</option>
                </Input>
              </FormGroup>
              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <MdLuggage style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Luggage</Label>
                <Input
                  type="select"
                  name="luggage"
                  value={values.luggage}
                  onChange={handleChange}
                >
                  <option value="1 luggage">1 luggage</option>
                  <option value="2 luggage">2 luggage</option>
                  <option value="3 luggage">3 luggage</option>
                  <option value="4 luggage">4 luggage</option>
                  <option value="5+ luggage">5+ luggage</option>
                </Input>
              </FormGroup>

              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <BsFillCalendarEventFill style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Journey Start Date</Label>
                <Input
                  readOnly={location.state?.fromHome}
                  type="date"
                  onKeyDown={(e) => e.preventDefault()}
                  name="journeyDate"
                  value={values?.journeyDate}
                  min={
                    !location.state?.fromHome &&
                    new Date().toJSON().slice(0, 10)
                  }
                  onChange={handleChange}
                  placeholder="Journey Start Date"
                />
              </FormGroup>
              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <BsFillCalendarEventFill style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Journey End date</Label>
                <Input
                  readOnly={location.state?.fromHome}
                  type="date"
                  disabled={
                    !location.state?.fromHome && values?.journeyDate === ""
                  }
                  onKeyDown={(e) => e.preventDefault()}
                  name="journeyEndDate"
                  value={values?.journeyEndDate}
                  min={
                    !location.state?.fromHome &&
                    values?.journeyDate &&
                    new Date(
                      new Date(values?.journeyDate)?.setDate(
                        new Date(values?.journeyDate)?.getDate() + 1
                      )
                    )
                      .toJSON()
                      .slice(0, 10)
                  }
                  onChange={handleChange}
                  placeholder="Journey End Date"
                />
              </FormGroup>

              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <BiSolidTime style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Journey Start time</Label>
                <Input
                  readOnly={location.state?.fromHome}
                  type="time"
                  name="journeyTime"
                  onKeyDown={(e) => e.preventDefault()}
                  value={values?.journeyTime}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Journey Start Time"
                  className="time__picker"
                />
              </FormGroup>
              <FormGroup className="booking__form d-inline-block me-2 ms-2 mb-4">
                <BiSolidTime style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Journey End time</Label>
                <Input
                  readOnly={location.state?.fromHome}
                  type="time"
                  name="journeyEndTime"
                  onKeyDown={(e) => e.preventDefault()}
                  value={values.journeyEndTime}
                  onChange={handleChange}
                  placeholder="Journey End Time"
                  className="time__picker"
                />
              </FormGroup>

              <FormGroup>
                <BiSolidNotepad style={{ color: "#7a7a87" }} />{" "}
                <Label style={{ color: "#7a7a87" }}>Any message</Label>
                <Input
                  rows={5}
                  type="textarea"
                  className="textarea"
                  placeholder="Note"
                />
              </FormGroup>

              <div className="booking__form-insurance-cont">
                <p style={{ fontWeight: 500, color: "#7a7a87" }}>
                  CHOOSE INSURANCE OPTIONS
                </p>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <p style={{ color: "#7a7a87" }}>Description</p>
                  <p style={{ color: "#7a7a87" }}>Price</p>
                </div>
                <FormGroup
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormGroup>
                    <Input
                      // checked={insurance}
                      name="insurance"
                      onChange={(e) => {
                        setInsurance(0);
                        setInsurancetype("a");
                      }}
                      type="radio"
                      className="booking__form-checkbox"
                    />
                    <Label style={{ color: "#000", fontWeight: 300 }} check>
                      Standard Excess ($3,000) @ $0.00 Per Day
                    </Label>
                    <br />
                    <Label style={{ color: "green", fontWeight: 300 }} check>
                      Standard excess insurance, include with your rental free
                      of charge. Basic cover with liability up to the specified
                      excess in the event of incident.
                    </Label>
                  </FormGroup>
                  <Label style={{ fontWeight: 600 }}>$0</Label>
                </FormGroup>
                <FormGroup
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormGroup>
                    <Input
                      // checked={insurance}
                      name="insurance"
                      onChange={(e) => {
                        setInsurance(29);
                        setInsurancetype("b");
                      }}
                      type="radio"
                      className="booking__form-checkbox"
                    />
                    <Label style={{ color: "#000", fontWeight: 300 }} check>
                      $0.00 Excess  @ $29.00 Per Day
                    </Label>
                    <br />
                    <Label style={{ color: "green", fontWeight: 300 }} check>
                      Stress free cover with a $0.00 excess in the event of an
                      accident. Excludes front windscreen.
                    </Label>
                  </FormGroup>
                  <Label style={{ fontWeight: 600 }}>
                    $ {days > 0 ? days * 29 : 0}
                  </Label>
                </FormGroup>
                <FormGroup
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <FormGroup>
                    <Input
                      // checked={insurance}
                      name="insurance"
                      onChange={(e) => {
                        setInsurance(15);
                        setInsurancetype("c");
                      }}
                      type="radio"
                      className="booking__form-checkbox"
                    />
                    <Label style={{ color: "#000", fontWeight: 300 }} check>
                      Comprehensive ($1500.00 Excess) @
                      $15.00 Per Day
                    </Label>
                    <br />
                    <Label style={{ color: "green", fontWeight: 300 }} check>
                      Stress free cover with a $0.00 excess in the event of an
                      accident. Includes front windscreen.
                    </Label>
                  </FormGroup>
                  <Label style={{ fontWeight: 600 }}>
                    $ {days > 0 ? days * 15 : 0}
                  </Label>
                </FormGroup>
              
              </div>
            </Form>
          </ReactPlaceholder>
        )}
      </div>

      <div className="payment text-end my-5">
        {fetching || loading ? (
          <Button disabled>
            <Spinner variant="light" />
          </Button>
        ) : (
          <Button
            onClick={() => onReserve()}
            disabled={
              insuranceType === "" ||
              ageErr ||
              userAge < 21 ||
              // !acceptedTermsCondi ||
              // values.firstname.length <= 0 ||
              // values.lastname.length <= 0 ||
              // values.email.length <= 0 ||
              values.fromAddress?.length <= 0 ||
              values.toAddress?.length <= 0 ||
              // values.mobile_no?.length <= 0 ||
              values.person?.length <= 0 ||
              values.luggage?.length <= 0 ||
              values.journeyDate?.length <= 0 ||
              values.journeyEndDate?.length <= 0 ||
              values.journeyTime?.length <= 0 ||
              values.journeyEndTime?.length <= 0 ||
              fetching ||
              loading
            }
          >
            Review and pay
          </Button>
        )}
      </div>

      <ToastContainer />
    </>
  );
};

export default BookingForm;
