import React, { useRef, useContext, useState, useEffect } from "react";

import { Container, Row, Col, Nav, Navbar, Button } from "reactstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  FaUserCircle,
  FaBars,
  FaUser,
  FaSignOutAlt,
  FaSpinner,
  FaArrowCircleDown,
  FaArrowCircleUp,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";
import Dropdown from "react-bootstrap/Dropdown";
import "../../styles/header.css";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../features/authSlice";
import { AiFillCar, AiOutlineClose } from "react-icons/ai";
import { HiMenuAlt2 } from "react-icons/hi";
import { BsGlobeAmericas } from "react-icons/bs";
import { BiTime } from "react-icons/bi";
import { setBookingCar } from "../features/carBookingSlice";
import { setBookingHistory } from "../features/bookingHistorySlice";
import { GrClose } from "react-icons/gr";
import app_logo from "./app_logo.png";

const navLinks = [
  {
    path: "/",
    display: "Home",
  },
  // {
  //   path: "/about",
  //   display: "About",
  // },
  {
    path: "/cars",
    display: "Cars",
  },
  {
    path: "/airport",
    display: "Airport Car Rental",
  },
  {
    path: "/luxury-car",
    display: "Luxury Car Rental",
  },
  {
    path: "/wedding",
    display: "Wedding Car Rental",
  },
  {
    path: "/AA_Road_Assistance",
    display: "AA Road Assistance",
  },
  {
    path: "/contact",
    display: "Contact",
  },
  // {
  //   path: "/faq",
  //   display: "FAQ",
  // },
  {
    path: "/user-guide",
    display: "User Guide",
  },
];
const navLinks2 = [
  {
    path: "/carrentalchristchurch",
    display: "Home",
  },
  {
    path: "/contact_us",
    display: "Contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);
  const [mobileNav, setMobileNav] = useState(navLinks);
  const [width, setWidth] = useState();
  const [arrowChg, setArrowChg] = useState(false);
  // const {state , dispatch : ctxDispatch} = useContext(Store);
  // const {userInfo,token} = state;
  const { token, user } = useSelector((state) => state.auth);
  const [scroll, setScroll] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = window.location;
  const isCustom =
    pathname.includes("carrentalchristchurch") ||
    pathname.includes("contact_us");
  console.log(pathname);

  const getNavLinks = () => {
    return isCustom ? navLinks2 : navLinks;
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (width > 768) {
        setScroll(window.scrollY > 50);
      } else {
        setScroll(false);
      }
    });
  }, [width]);
  const CallButton = () => {
    window.location.href = "tel:0800555988";
  };

  const signoutHandler = () => {
    dispatch(logOut());
    dispatch(setBookingCar());
    dispatch(setBookingHistory());

    navigate("/");
  };

  const toggleMenu = () => {
    if (token) {
      setMobileNav([
        ...getNavLinks(),

        ...(!isCustom
          ? [
              {
                path: "/profile",
                display: "My Account",
              },
            ]
          : []),
      ]);
    } else {
      setMobileNav([
        ...getNavLinks(),

        ...(!isCustom
          ? [
              {
                path: "/sign-in",
                display: "Login",
              },
              {
                path: "/sign-up",
                display: "Register",
              },
            ]
          : []),
      ]);
    }

    menuRef.current.classList.toggle("menu__active");
    setScroll(false);
  };

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener("resize", () => setWidth(window.innerWidth));

    if (width > 768) {
      setMobileNav((menu) =>
        menu.filter((links) => {
          return (
            links.path !== "/sign-in" &&
            links.path !== "/sign-up" &&
            links.path !== "/profile"
          );
        })
      );
    } else {
      setMobileNav([
        ...getNavLinks(),

        ...(!isCustom
          ? [
              {
                path: "/sign-in",
                display: "Login",
              },
              {
                path: "/sign-up",
                display: "Register",
              },
            ]
          : []),
        ,
      ]);
    }
  }, [width, pathname]);

  useEffect(() => {
    setMobileNav([...getNavLinks()]);
  }, [pathname]);
  const closeMenu = () => {
    setMobileNav((menu) =>
      menu.filter((links) => {
        return (
          links.path !== "/sign-in" &&
          links.path !== "/sign-up" &&
          links.path !== "/profile"
        );
      })
    );

    menuRef.current.classList.toggle("menu__active");
    setScroll(false);
  };

  console.log(mobileNav);
  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <Container>
          <Row>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontWeight: 600,
              }}
            >
              <div className="header__top__left">
                <span>Need Help?</span>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="tel:+64-220211997"
                  className="header__top__help"
                >
                  <i class="ri-phone-fill"></i> +64-220211997
                </a>
                <a
                  style={{ textDecoration: "none", color: "black" }}
                  href="tel:0800 555 988"
                  className="header__top__help"
                >
                  <i class="ri-phone-fill"></i> 0800 555 988
                </a>
              </div>
            </Col>

            {/* {token ? (
              <Col lg="6" md="6" sm="6">
                <div className="drp-dwn">
                  <Dropdown>
                    <Dropdown.Toggle id="user_profile" className="btn-primary">
                      <FaUserCircle size={"25px"} />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Header>
                        Signed in as
                        <br />
                        <b>{user?.name}</b>
                      </Dropdown.Header>

                      <Dropdown.Divider />
                      <Dropdown.Item href="/profile">
                        {" "}
                        <FaUser className="me-2" /> My account{" "}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={signoutHandler}>
                        {" "}
                        <FaSignOutAlt className="icon-md me-2" /> Log Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </Col>
            ) : (
              <Col lg="6" md="6" sm="6">
                <div className="header__top__right d-flex align-items-center justify-content-end gap-3">
                  <Link
                    to="/sign-in"
                    className=" d-flex align-items-center gap-1"
                  >
                    <i class="ri-login-circle-line"></i> Login
                  </Link>

                  <Link
                    to="/sign-up"
                    className=" d-flex align-items-center gap-1"
                  >
                    <i class="ri-user-line"></i> Register
                  </Link>
                </div>
              </Col>
            )} */}
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row className="align-items-center">
            <Col
              lg="3"
              md="3"
              sm="4"
              className="align-items-center justify-content-center"
            >
              <div className="">
                <>
                  <Link
                    to={isCustom ? "/carrentalchristchurch" : "/"}
                    className=""
                  >
                    {/* <i class="ri-car-line"></i> */}
                    {/* <AiFillCar /> */}
                    <img
                      src={app_logo}
                      alt={"Christchurch Car Rentals"}
                      className="app-logo"
                      // width={150}
                    />
                    {/* <span>
                      Palmerston North <br /> Car Rentals
                    </span> */}
                  </Link>
                </>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2 logo">
                <span>
                  {/* <i class="ri-earth-line"></i> */}
                  <BsGlobeAmericas
                    style={{ fontSize: "2rem", color: "#49a6ba" }}
                  />
                </span>
                <div className="header__location-content">
                  <h4>New Zealand</h4>
                  <h6>Christchurch</h6>
                </div>
              </div>
            </Col>

            <Col lg="3" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  {/* <i class="ri-time-line"></i> */}
                  <BiTime style={{ fontSize: "2rem", color: "#49a6ba" }} />
                </span>
                <div className="header__location-content">
                  <h4>Monday - Sunday</h4>
                  <h6>24 hours open by Phone</h6>
                </div>
              </div>
            </Col>

            <Col
              lg="3"
              md="3"
              sm="0"
              className=" d-flex align-items-center justify-content-end "
            >
              <button className="header__btn btn ">
                <Link onClick={CallButton}>
                  <i class="ri-phone-line"></i> Request a call
                </Link>
              </button>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ========== main navigation =========== */}

      <div className={scroll ? `main__navbar main-navbar-fix` : `main__navbar`}>
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <HiMenuAlt2 onClick={toggleMenu} />
              {/* <i class="ri-menu-line" onClick={toggleMenu}></i> */}
            </span>

            <div className="navigation" ref={menuRef}>
              <div className="menu justify-content-between">
                {width < 768 && (
                  <div className="close-icon d-flex align-items-center justify-content-between">
                    <div onClick={closeMenu} className="">
                      <>
                        <Link
                          to={isCustom ? "/carrentalchristchurch" : "/"}
                          className=""
                        >
                          {/* <i class="ri-car-line"></i> */}
                          {/* <AiFillCar /> */}
                          <img
                            src={app_logo}
                            alt={"Christchurch Car Rentals"}
                            className="app-logo"
                            // width={150}
                          />
                          {/* <span>
                      Palmerston North <br /> Car Rentals
                    </span> */}
                        </Link>
                      </>
                    </div>

                    <AiOutlineClose onClick={closeMenu} />
                  </div>
                )}
                <div className="menu-items">
                  {mobileNav?.map((item, index) => (
                    <NavLink
                      onClick={closeMenu}
                      to={item.path}
                      style={{
                        paddingTop: item.path === "/sign-in" && "0.5rem",
                        borderTop:
                          item.path === "/sign-in" && "0.5px solid #000d6b",
                        width: item.path === "/sign-in" && "70%",
                        textAlign: item.path === "/sign-in" && "center",
                      }}
                      className={(navClass) =>
                        navClass.isActive
                          ? "nav__active nav__item"
                          : "nav__item"
                      }
                      key={index}
                    >
                      {item.display === "My Account" ? (
                        <div
                          style={{ flexDirection: "column", display: "flex" }}
                        >
                          <Button className="header-mobile-myaccBtn">
                            {item.display}
                          </Button>
                          <Button
                            onClick={signoutHandler}
                            className="header-mobile-logoutBtn my-4"
                          >
                            Log-out
                          </Button>
                        </div>
                      ) : (
                        item.display
                      )}
                    </NavLink>
                  ))}
                </div>

                <div className="header-mobile-btm">
                  {width < 768 && (
                    <div className="header__btn-call-mobile">
                      <button className="header__btn">
                        <Link
                        
                          onClick={ ()=> {closeMenu() 
                            CallButton()
                          }}
                        >
                          <i class="ri-phone-line"></i> Request a call
                        </Link>
                      </button>
                    </div>
                  )}

                  {width < 768 && (
                    <div className="header-mobile-btm-info">
                      <div className="header-mobile-btm-infoDiv">
                        <div className="header__location d-flex align-items-center gap-2 logo">
                          <span>
                            {/* <i class="ri-earth-line"></i> */}
                            <BsGlobeAmericas
                              style={{ fontSize: "2rem", color: "#49a6ba" }}
                            />
                          </span>
                          <div className="header__location-content">
                            <h4>New Zealand</h4>
                            <h6>Christchurch</h6>
                          </div>
                        </div>
                      </div>

                      <div className="header-mobile-btm-infoDiv">
                        <div className="header__location d-flex align-items-center gap-2">
                          <span>
                            {/* <i class="ri-time-line"></i> */}
                            <BiTime
                              style={{ fontSize: "2rem", color: "#49a6ba" }}
                            />
                          </span>
                          <div className="header__location-content">
                            <h4>Monday - Sunday</h4>
                            <h6>24 hours open by Phone</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {width > 768 && !isCustom && (
              <div>
                {token ? (
                  <Col lg="6" md="6" sm="6">
                    <div className="drp-dwn">
                      <Dropdown
                        onToggle={() => {
                          setArrowChg(!arrowChg);
                        }}
                      >
                        <Dropdown.Toggle
                          id="user_profile"
                          className="header-acc-dropdown"
                        >
                          <FaUserCircle size={"25px"} />
                          {arrowChg ? (
                            <FaArrowUp className="header-acc-dropdown-up" />
                          ) : (
                            <FaArrowDown className="header-acc-dropdown-down" />
                          )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Header>
                            Welcome
                            <br />
                            <b>{user?.name}</b>
                          </Dropdown.Header>

                          <Dropdown.Divider />
                          <Dropdown.Item href="/profile">
                            {" "}
                            <FaUser className="me-2" /> My account{" "}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={signoutHandler}>
                            {" "}
                            <FaSignOutAlt className="icon-md me-2" /> Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </Col>
                ) : (
                  <Col lg="12" md="6" sm="5">
                    <div className="header__top__right d-flex flex-row md:flex-column align-items-center justify-content-end gap-3">
                      <Link
                        to="/sign-in"
                        className="d-flex align-items-center gap-1"
                      >
                        <i className="ri-login-circle-line"></i> Login
                      </Link>
                      <Link
                        to="/sign-up"
                        className="d-flex align-items-center gap-1"
                      >
                        <i className="ri-user-line"></i> Register
                      </Link>
                    </div>
                  </Col>
                )}
              </div>
            )}

            {width < 768 && (
              <div className="">
                <>
                  <Link
                    to={isCustom ? "/carrentalchristchurch" : "/"}
                    className=""
                  >
                    {/* <i class="ri-car-line"></i> */}
                    {/* <AiFillCar /> */}
                    <img
                      src={app_logo}
                      alt={"Christchurch Car Rentals"}
                      className=""
                      width={60}
                      style={{ marginTop: "0.3rem !important" }}
                      // width={150}
                    />
                    {/* <span>
                      Palmerston North <br /> Car Rentals
                    </span> */}
                  </Link>
                </>
              </div>
            )}

            {/* <div className="nav__right">
              <div className="search__box">
                <input type="text" placeholder="Search" />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
